<template>
  <div>
    <!-- 기본정보 -->
    <div class="info-container">
      <!-- 헤더 -->
      <div class="info-header-wrap flex-space-between">
        <div class="flex-align-center">
          <span class="header-category">기본정보</span>
        </div>
      </div>
      <!-- 신청일시 -->
      <div class="info-content-wrap">
        <span class="content-category">신청일시</span>
        <span>{{ signData.regDate }}</span>
      </div>
      <!-- 유형 -->
      <div class="info-content-wrap">
        <span class="content-category">유형</span>
        <span>{{ getEditType(signData.type) }}</span>
      </div>
    </div>
    <!-- 신청인 정보(신규, 기본정보) -->
    <div
      class="info-container"
      v-if="infoData && (signData.type === 'NEW' || signData.type === 'BASIC')"
    >
      <!-- 헤더 -->
      <div class="info-header-wrap flex-space-between">
        <div class="flex-align-center">
          <span class="header-category">신청인 정보</span>
        </div>
      </div>
      <!-- 이름 -->
      <div class="info-content-wrap">
        <span class="content-category">이름</span>
        <span>{{ infoData.name }} {{ infoData.gender }}</span>
      </div>
      <!-- 로그인 ID -->
      <div class="info-content-wrap">
        <span class="content-category">로그인 ID</span>
        <span>{{ infoData.email }}</span>
      </div>
      <!-- 생년월일 -->
      <div class="info-content-wrap">
        <span class="content-category">생년월일</span>
        <span>{{ infoData.birth }}</span>
      </div>
      <!-- 휴대폰번호 -->
      <div class="info-content-wrap">
        <span class="content-category">휴대폰번호</span>
        <span>{{ formatPhone(infoData.phone) }}</span>
        <span
          class="content-edit-info"
          v-if="editData && infoData.phone !== editData.phone"
          >{{ formatPhone(editData.phone) }}</span
        >
      </div>
      <!-- 거주지역 -->
      <div class="info-content-wrap">
        <span class="content-category">거주지역</span>
        <span>{{ infoData.addr }} {{ infoData.addr2 }}</span>
        <span
          class="content-edit-info"
          v-if="
            editData &&
            (infoData.addr !== editData.addr ||
              infoData.addr2 !== editData.addr2)
          "
          >{{ editData.addr }} {{ editData.addr2 }}</span
        >
      </div>
      <!-- 분야 -->
      <div class="info-content-wrap">
        <span class="content-category">분야</span>
        <span
          >{{
            infoData.highSpecialtyEsntlId ? infoData.highSpecialtyTitle : "없음"
          }}
          >
          {{
            infoData.lowSpecialtyEsntlId ? infoData.lowSpecialtyTitle : "없음"
          }}
          >
          {{ infoData.orderSpecialty ? infoData.orderSpecialty : "없음" }}</span
        >
        <span
          class="content-edit-info"
          v-if="
            editData &&
            (infoData.highSpecialtyEsntlId !== editData.highSpecialtyEsntlId ||
              infoData.lowSpecialtyEsntlId !== editData.lowSpecialtyEsntlId ||
              infoData.orderSpecialty !== editData.orderSpecialty)
          "
          >{{ checkData("highSpecialtyEsntlId") }}
          >
          {{ checkData("lowSpecialtyEsntlId") }}
          >
          {{ checkData("orderSpecialty") }}</span
        >
      </div>
      <!-- 경력 -->
      <div class="info-content-wrap">
        <span class="content-category">경력</span>
        <span>{{ infoData.career }}</span>
        <span
          class="content-edit-info"
          v-if="editData && infoData.career !== editData.career"
          >{{ editData.career }}</span
        >
      </div>
      <!-- 자격증명 URL -->
      <div class="info-content-wrap">
        <span class="content-category">자격증명 URL</span>
        <span>{{ infoData.proofUrl }}</span>
        <span
          class="content-edit-info"
          v-if="editData && infoData.proofUrl !== editData.proofUrl"
          >{{ editData.proofUrl }}</span
        >
      </div>
      <!-- 결제항목 파일 -->
      <div class="info-content-wrap">
        <span class="content-category">결제항목 파일</span>
        <span
          style="text-decoration: underline; cursor: pointer"
          @click="downloadFile"
          >{{ infoData.proofFileOri }}</span
        >
        <span
          class="content-edit-info"
          v-if="editData && infoData.proofFileOri !== editData.proofFileOri"
          >{{ editData.proofFileOri }}</span
        >
      </div>
    </div>

    <!-- 신청인 정보(사업자 정보) -->
    <div class="info-container" v-if="infoData && signData.type === 'COMP'">
      <!-- 헤더 -->
      <div class="info-header-wrap flex-space-between">
        <div class="flex-align-center">
          <span class="header-category">신청인 정보</span>
        </div>
      </div>
      <!-- 상호명 -->
      <div class="info-content-wrap">
        <span class="content-category">상호명</span>
        <span>{{ infoData.compName }}</span>
        <span
          class="content-edit-info"
          v-if="editData && infoData.compName !== editData.compName"
          >{{ editData.compName }}</span
        >
      </div>
      <!-- 사업자등록번호 -->
      <div class="info-content-wrap">
        <span class="content-category">사업자등록번호</span>
        <span>{{ infoData.compRegNumber }}</span>
        <span
          class="content-edit-info"
          v-if="editData && infoData.compRegNumber !== editData.compRegNumber"
          >{{ editData.compRegNumber }}</span
        >
      </div>
      <!-- 대표자 이름 -->
      <div class="info-content-wrap">
        <span class="content-category">대표자 이름</span>
        <span>{{ infoData.compCeoName }}</span>
        <span
          class="content-edit-info"
          v-if="editData && infoData.compCeoName !== editData.compCeoName"
          >{{ editData.compCeoName }}</span
        >
      </div>
      <!-- 사업장 소새지 -->
      <div class="info-content-wrap">
        <span class="content-category">사업장 소재지</span>
        <span>{{ infoData.compAddr }} {{ infoData.compAddr2 }}</span>
        <span
          class="content-edit-info"
          v-if="
            editData &&
            (infoData.compAddr !== editData.compAddr ||
              infoData.compAddr2 !== editData.compAddr2)
          "
          >{{ editData.compAddr }} {{ editData.compAddr2 }}</span
        >
      </div>
      <!-- 사업자 구분 -->
      <div class="info-content-wrap">
        <span class="content-category">사업자 구분</span>
        <span>{{ infoData.compClass }}</span>
        <span
          class="content-edit-info"
          v-if="editData && infoData.compClass !== editData.compClass"
          >{{ editData.compClass }}</span
        >
      </div>
      <!-- 업태 -->
      <div class="info-content-wrap">
        <span class="content-category">업태</span>
        <span>{{ infoData.compStatus }}</span>
        <span
          class="content-edit-info"
          v-if="editData && infoData.compStatus !== editData.compStatus"
          >{{ editData.compStatus }}</span
        >
      </div>
      <!-- 업종 -->
      <div class="info-content-wrap">
        <span class="content-category">업종</span>
        <span>{{ infoData.compType }}</span>
        <span
          class="content-edit-info"
          v-if="editData && infoData.compType !== editData.compType"
          >{{ editData.compType }}</span
        >
      </div>
    </div>

    <!-- 신청인 정보(정산 정보) -->
    <div class="info-container" v-if="infoData && signData.type === 'BANK'">
      <!-- 헤더 -->
      <div class="info-header-wrap flex-space-between">
        <div class="flex-align-center">
          <span class="header-category">신청인 정보</span>
        </div>
      </div>
      <!-- 정산방법 -->
      <div class="info-content-wrap">
        <span class="content-category">정산방법</span>
        <span>계좌입금</span>
      </div>
      <!-- 입금계좌 -->
      <div class="info-content-wrap">
        <span class="content-category">입금계좌</span>
        <span
          >{{ infoData.bankName }}{{ infoData.accountNumber
          }}<span v-if="infoData.accountHolder"
            >(예금주:{{ infoData.accountHolder }})</span
          ></span
        >
        <span
          class="content-edit-info"
          v-if="
            editData &&
            (infoData.bankName !== editData.bankName ||
              infoData.accountNumber !== editData.accountNumber ||
              infoData.accountHolder !== editData.accountHolder)
          "
          >{{ editData.bankName }}{{ editData.accountNumber
          }}<span
            class="content-edit-info"
            style="margin-left: 0"
            v-if="editData.accountHolder"
            >(예금주:{{ editData.accountHolder }})</span
          ></span
        >
      </div>
    </div>
    <!-- 아래 버튼 -->
    <div class="fixed d-flex align-items-center flex-end under-finish">
      <div>
        <button-common
          :size="SIZE_T"
          :clr="CLR_W"
          wp-cls="mr-8"
          @click="goCompanyStatusList"
          >돌아가기</button-common
        >
        <button-common
          :size="SIZE_T"
          :clr="CLR_W"
          wp-cls="mr-8"
          style="color: #f1431d"
          @click="openRejectReason"
          >승인 거절</button-common
        >
        <button-common :size="SIZE_T" :clr="CLR_M" @click="doApprove"
          >승인</button-common
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CompanyStatusDetail",
  mixins: [],
  components: {},

  /*** data ***/
  data() {
    return {
      signData: {
        regDate: "",
        type: "",
      },
      infoData: null,
      editData: null,
      rejectReason: "",
    };
  },

  /*** created ***/
  created() {},

  /*** mounted ***/
  async mounted() {
    if (this.$route.params.id) {
      await this.getSignData();
    }
    if (this.signData.type === "COMP") {
      await this.getBusinessData();
    }
    if (this.signData.type === "BANK") {
      await this.getBankData();
    }
    if (this.signData.type === "BASIC") {
      await this.getBasicData();
    }
  },

  /*** computed ***/
  computed: {
    ...mapGetters(["G_REJECT_REASON"]),
  },

  /*** watch ***/
  watch: {
    async G_REJECT_REASON(val) {
      // 열림
      if (val) {
        this.rejectReason = this["G_REJECT_REASON"];
        if (this.rejectReason) {
          this.doReject();
        }
      }
    },
  },

  /*** methods ***/
  methods: {
    /** @ 회원가입 정보 조회 ***/
    async getSignData() {
      const rs = await this.$axios({
        url: "/admin/partner/sign/data",
        params: { esntlId: this.$route.params.id },
      });
      if (rs.result === "SUC") {
        this.signData.regDate = rs.resultData.regDate;
        this.signData.type = rs.resultData.editType;
        if (this.signData.type === "NEW") {
          this.infoData =
            rs.resultData.nextInfo.length > 0
              ? rs.resultData.nextInfo[0]
              : null;
        }
      } else {
        console.log("회원가입 정보 조회 실패");
      }
    },

    /** @ 기본 정보 조회 ***/
    async getBasicData() {
      const rs = await this.$axios({
        url: "/admin/partner/basic/data",
        params: { esntlId: this.$route.params.id },
      });
      if (rs.result === "SUC") {
        this.infoData =
          rs.resultData.prevInfo.length > 0 ? rs.resultData.prevInfo[0] : null;
        this.editData =
          rs.resultData.nextInfo.length > 0 ? rs.resultData.nextInfo[0] : null;
      } else {
        console.log("기본 정보 조회 실패");
      }
    },

    /** @ 사업자 정보 조회 ***/
    async getBusinessData() {
      const rs = await this.$axios({
        url: "/admin/partner/comp/data",
        params: { esntlId: this.$route.params.id },
      });
      if (rs.result === "SUC") {
        this.infoData =
          rs.resultData.prevInfo.length > 0 ? rs.resultData.prevInfo[0] : null;
        this.editData =
          rs.resultData.nextInfo.length > 0 ? rs.resultData.nextInfo[0] : null;
      } else {
        console.log("사업자 정보 조회 실패");
      }
    },

    /** @ 정산 정보 조회 ***/
    async getBankData() {
      const rs = await this.$axios({
        url: "/admin/partner/bank/data",
        params: { esntlId: this.$route.params.id },
      });
      if (rs.result === "SUC") {
        this.infoData =
          rs.resultData.prevInfo.length > 0 ? rs.resultData.prevInfo[0] : null;
        this.editData =
          rs.resultData.nextInfo.length > 0 ? rs.resultData.nextInfo[0] : null;
      } else {
        console.log("정산 정보 조회 실패");
      }
    },

    /** @ 승인 ***/
    async doApprove() {
      const rs = await this.$axios({
        url: "/admin/partner/approval",
        params: { esntlId: this.$route.params.id },
      });
      if (rs.result === "SUC") {
        this.goCompanyStatusList();
      } else {
        console.log("승인 실패");
      }
    },

    async openRejectReason() {
      this.$store.dispatch("A_REJECT_REASON_DISABLED", false);
      this.$store.dispatch("A_REJECT_REASON", "");
      await this.$openRejectReason({}, this);
    },

    /** @ 승인 거절 ***/
    async doReject() {
      const rs = await this.$axios({
        url: "/admin/partner/reject",
        params: {
          esntlId: this.$route.params.id,
          rejReason: this.rejectReason,
        },
      });
      if (rs.result === "SUC") {
        this.goCompanyStatusList();
      } else {
        console.log("승인 거절 실패");
      }
    },

    getEditType(type) {
      switch (type) {
        case "COMP":
          return "변경_사업자";
        case "BASIC":
          return "변경_기본";
        case "BANK":
          return "변경_정산";
        case "NEW":
          return "신규";
      }
    },

    formatPhone(num) {
      if (!num) {
        return "";
      }
      if (/^(02|0[3-9]{1}[0-9]{1})-?[0-9]{3}-?[0-9]{4,}$/.test(num)) {
        if ((/^(02)/.test(num) && num.length >= 10) || num.length >= 13) {
          return num
            .replace(/[-]/g, "")
            .replace(
              /(^02.{0}|^01.{1}|[0-9]{3})([0-9]{4})([0-9]{4})/,
              "$1-$2-$3"
            );
        }
        return num.replace(
          /(^02.{0}|^01.{1}|[0-9]{3})([0-9]{3})([0-9]{4})/,
          "$1-$2-$3"
        );
      }
      return num.replace(
        /(^02.{0}|^01.{1}|[0-9]{3})([0-9]{4})([0-9]{4})/,
        "$1-$2-$3"
      );
    },

    checkData(key) {
      if (this.editData[key] === null) {
        if (key === "highSpecialtyEsntlId") {
          return this.infoData.highSpecialtyTitle;
        } else if (key === "lowSpecialtyEsntlId") {
          return this.infoData.lowSpecialtyTitle;
        } else {
          return this.infoData[key];
        }
      } else {
        if (this.editData[key] === "") {
          return "없음";
        } else {
          if (key === "highSpecialtyEsntlId") {
            return this.editData.highSpecialtyTitle;
          } else if (key === "lowSpecialtyEsntlId") {
            return this.editData.lowSpecialtyTitle;
          } else {
            return this.editData[key];
          }
        }
      }
    },

    goCompanyStatusList() {
      this.$router.back();
    },

    downloadFile() {
      const url = `https://medal.sailors.kr/partner/download?file=${this.infoData.proofFile}&oriFile=${this.infoData.proofFileOri}`;
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", url); //or any other extension
      document.body.appendChild(link);
      link.click();
    },
  },
};
</script>

<style scoped lang="scss">
.info-container {
  width: 100%;
  background: #fff;
  border: 1px solid #d8dae5;
  margin-top: 10px;
}
.info-header-wrap {
  width: 100%;
  padding: 16px 32px;
  border-bottom: 1px solid #d8dae6;
}
.header-category {
  color: #515466;
  font-size: 16px;
  font-weight: 700;
  margin-right: 32px;
}
.header-status-complete {
  color: #2CCDC3;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.header-status-reject {
  color: #f1431d;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.header-status-process {
  color: #515466;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.header-status-date {
  color: blue;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
  cursor: pointer;
}
.header-status-reason {
  color: blue;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
.info-content-wrap {
  padding: 16px 0;
  display: flex;
  align-items: center;
}
.content-category {
  color: #828699;
  padding-left: 32px;
  width: 200px;
}
.content-edit-info {
  margin-left: 32px;
  color: #ff755a;
  font-weight: 500;
  font-size: 14px;
}
</style>
